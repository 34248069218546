<script setup></script>

<template>
    <v-row id="disclosure" class="justify-center">
        <v-col class="st-subheader text-center">
            Disclosure: Some links on this page are "affiliate links." This means that if you click on the link and
            purchase that item, we will receive an affiliate commission. We appreciate your help and support.
        </v-col>
    </v-row>
</template>
